<template>
  <div class="w-52 h-52">
    <img :src="`${cdnURL}loader-aevent.gif`" alt="loader" loading="lazy" />
  </div>
</template>

<script setup>
// CDN set up
const config = useRuntimeConfig();
const cdnURL = config.public.cdnURL;
</script>
